<template>
  <div class="c-bg-gray-100 c-d-flex ui-flex-col ui-min-h-screen ui-h-auto">
    <header-profile
      :user="user"
      :loading="isLoading"
    />

    <hero
      :user="user"
      :loading="isLoading"
      :is-visitor-view="isVisitorView"
      class="ui-flex-shrink-0"
    />

    <c-tabs
      :tabs="tabs"
      :loading="isLoading"
      :is-visitor-view="isVisitorView"
      class="ui-flex-shrink-0"
    />

    <div class="c-container ui-py-8 c-d-flex ui-flex-col ui-flex-grow">
      <router-view
        :user="user"
        :loading="isLoading"
        :is-visitor-view.sync="isVisitorView"
        class="c-d-flex ui-flex-col ui-flex-1"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CTabs from '@/components/c-tabs';
import HeaderProfile from './header/header';
import Hero from './hero/hero';
import { UserStoreKeys } from '@/store/modules/user';
import { GlobalStoreKeys } from '@/store/modules/global';

export default {
  name: 'ProfileLayout',
  components: {
    HeaderProfile,
    CTabs,
    Hero,
  },
  data() {
    return {
      isVisitorView: false,
      tabs: [
        {
          icon: 'user-field',
          label: 'Meu Perfil',
          to: '/meu-perfil',
        },
        {
          icon: 'playlist-check',
          label: 'Meus Dados',
          to: '/meus-dados',
        },
        {
          icon: 'e-commerce',
          label: 'Minhas Compras',
          to: '/minhas-compras',
        },
        {
          cicon: 'bank-card-2',
          label: 'Meus Pagamentos',
          to: '/dados-pagamento',
        },
        {
          cicon: 'lock',
          label: 'LGPD',
          to: '/privacidade',
        },
      ],
    };
  },
  async mounted() {
    await this.$store.dispatch(GlobalStoreKeys.ACTION_UPDATE_PAGE_LOADING, true);
    await this.$store.dispatch(UserStoreKeys.ACTION_FETCH_LOGGED_USER);
    await this.$store.dispatch(GlobalStoreKeys.ACTION_UPDATE_PAGE_LOADING, false);
  },
  computed: {
    ...mapGetters({
      user: UserStoreKeys.GET_LOGGED_USER,
      isLoading: GlobalStoreKeys.GET_PAGE_LOADING,
    }),
  },
};
</script>
